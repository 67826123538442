import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";

export enum ProductCatalogType {
  Promo = 1,
  Main = 2,
}

export interface IMainSlide {
  id: number;
  name: string;
  payload: IYaSlotConfig;
}

export interface ITicker {
  id: number;
  offerURL: string;
  publicImageURL: string;
  disclaimer?: string;
  title?: string;
}

export interface IPublication {
  article: {
    id: number;
    code: string;
  };
  description: string;
  disclaimer: string;
  id: number;
  publicImageURL: string;
  title: string;
}

export interface IPublicationsResponse {
  data: IPublication[];
  pagination: {
    page: number;
    size: number;
    totalCount: number;
    totalPages: number;
  };
}

export interface IArticle {
  code: string;
  disclaimer: string;
  goodCategory: string;
  id: number;
  imageURL: string;
  isActive: boolean;
  name: string;
  partnerGoodsTitle?: string;
  publicImageURL: string;
  publishDate: string;
  text: string;
  title: string;
}

export interface IBrand {
  id: number;
  disclaimer: string;
  imageURL: string;
  isActive: boolean;
  offerURL: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageURL: string;
  showEndDate: string;
  showStartDate: string;
  title: string;
}

export interface IBrandsResponse {
  items: IBrand[];
  total: number;
}

export interface IGiftPackage {
  id: number;
  description: string;
  disclaimer: string;
  imageURL: string;
  isActive: boolean;
  offerURL: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageURL: string;
  showEndDate: string;
  showStartDate: string;
  title: string;
}

export interface IGiftPackagesResponse {
  items: IGiftPackage[];
}

export interface ICatalogSectionItem {
  id: number;
  categoryId?: number;
  categoryType: ProductCatalogType;
  imageURL: string;
  name: string;
  position: number;
  publicImageURL: string;
}

export interface ISpecialOffer {
  id: number;
  description: string;
  disclaimer: string;
  imageURL: string;
  isActive: boolean;
  offerURL: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageURL: string;
  showEndDate: string;
  showStartDate: string;
  slideType: number;
  title: string;
}

export interface ISpecialOffersResponse {
  items: ISpecialOffer[];
}

export interface IPrivateLabel {
  id: number;
  disclaimer: string;
  imageURL: string;
  isActive: boolean;
  offerURL: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageURL: string;
  showEndDate: string;
  showStartDate: string;
  title: string;
}

export interface IPrivateLabelsResponse {
  items: IPrivateLabel[];
}
