<script setup lang="ts">
import { ref, computed } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import VStack from "@magnit/core/src/components/VStack/VStack.vue";
import VStackItem from "@magnit/core/src/components/VStack/VStackItem.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VTitle from "~/components/VTitle.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import { Routes } from "~/utils/routes";
import ButterflySVG from "~/assets/svg/butterflies/home-catalog.svg";
import { useCategoriesStore } from "~/store/categories";
import { useStoresStore } from "~/store/stores";
import { ProductCatalogType } from "~/typings/api/content";
import contentApi from "~/api/contentApi";

const { requestCategories } = contentApi();

const { data: categories } = await requestCategories();

interface ICatalogItem {
  name: string;
  treeName?: string;
  url: string;
  class?: string;
  categoryId?: number;
  publicImageUrl: string;
}

const stackGap = { xs: "8px", s: "12px", m: "20px", ml: "24px" };
const categoriesStore = useCategoriesStore();
const storesStore = useStoresStore();

const itemsList = computed(() => {
  return categories.value?.map((item, ind) => {
    const className = ind === 0 ? "catalog-preview__item_text-white" : "";

    const category = item.categoryId
      ? categoriesStore.getCategoryById(
          item.categoryId.toString(),
          item.categoryType === ProductCatalogType.Promo ? "promo" : "goods",
        )
      : null;
    let url = `${
      item.categoryType === ProductCatalogType.Promo
        ? Routes.PromoCatalog
        : Routes.Catalog
    }`;
    if (category) {
      url += `/${item.categoryId}-${category.code}`;
    }
    url += `?shopCode=${storesStore.requestShopCode}`;

    const treeName = category
      ? categoriesStore.getTreeNameByKey(category.key)
      : item.name;

    return {
      name: item.name,
      categoryId: item.categoryId,
      publicImageUrl: item.publicImageURL,
      url,
      treeName,
      class: className,
    };
  });
});

const items = computed(() => [
  [itemsList.value?.slice(0, 2), itemsList.value?.slice(2, 4)],
  [itemsList.value?.slice(4, 6), itemsList.value?.slice(6, 8)],
]);

// Analytics
const { send } = useAnalytics();
const root = ref(null);
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("MainPage:Catalog:View");
  }
};

function onVisibilityItem(
  item: ICatalogItem,
  [{ isIntersecting }]: IntersectionObserverEntry[],
) {
  if (isIntersecting) {
    send("MainPage:Catalog:Item:View", {
      category_name: item.treeName,
      dest: item.url,
    });
  }
}

const onCategoryClick = (item: ICatalogItem) => {
  send("MainPage:Catalog:Item:Click", {
    category_name: item.treeName,
    dest: item.url,
  });
};
</script>

<template>
  <VWrapperLocal
    v-if="categories?.length"
    ref="root"
    v-intersection-observer="onVisibility"
  >
    <div class="catalog-preview">
      <VTitle>Каталог</VTitle>

      <ButterflySVG class="catalog-preview__butterfly" />
      <VStack class="catalog-preview__items" direction="row" :gap="stackGap">
        <VStackItem
          v-for="(grid, indGrid) in items"
          :key="indGrid"
          :size="{ xs: 12, ml: 6 }"
        >
          <VStack direction="row" :gap="stackGap">
            <VStackItem
              v-for="(col, indCol) in grid"
              :key="indCol"
              :gap="stackGap"
              :size="6"
            >
              <VStack>
                <NuxtLink
                  v-for="(item, indItem) in col"
                  :key="indItem"
                  :to="item?.url"
                  @click="onCategoryClick(item)"
                >
                  <div
                    v-intersection-observer="[
                      (io) => onVisibilityItem(item, io),
                      { root },
                    ]"
                    class="catalog-preview__item"
                    :class="item.class"
                  >
                    <VText
                      class="catalog-preview__caption"
                      tag="div"
                      :font="{
                        xs: 'body-small-accent',
                        m: 'body-large-accent',
                        l: 'title-medium',
                      }"
                    >
                      {{ item.name }}
                    </VText>

                    <img
                      class="catalog-preview__image"
                      :src="item.publicImageUrl"
                      alt=""
                      loading="lazy"
                    >
                  </div>
                </NuxtLink>
              </VStack>
            </VStackItem>
          </VStack>
        </VStackItem>
      </VStack>
    </div>
  </VWrapperLocal>
</template>

<style lang="postcss">
.catalog-preview {
  position: relative;

  &__butterfly {
    position: absolute;
    right: -12px;
    top: -63px;
    z-index: var(--pl-z-index-negative);
    display: none;
  }

  &__item {
    cursor: pointer;
    position: relative;
    background-color: var(--pl-surface-muted-default);
    border-radius: 16px;
    overflow: hidden;
    will-change: transform;
  }

  &__image {
    width: 100%;
    display: block;
    transition: 0.3s ease-out;

    .catalog-preview__item:hover & {
      transform: scale(1.1);
    }
  }

  &__caption {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    z-index: var(--pl-z-index-level1);

    .catalog-preview__item_text-white & {
      color: var(--pl-text-invert-primary);
    }
  }
}

@media (--pl-viewport-from-m) {
  .catalog-preview {
    &__item {
      border-radius: 20px;
    }

    &__caption {
      top: 16px;
      left: 16px;
      right: 16px;
    }
  }
}

@media (--pl-viewport-from-l) {
  .catalog-preview {
    &__butterfly {
      display: block;
    }

    &__item {
      border-radius: 24px;
    }

    &__caption {
      top: 20px;
      left: 20px;
      right: 20px;
    }
  }
}

@media (--pl-viewport-from-xl) {
  .catalog-preview__item {
    border-radius: 32px;
  }
}
</style>
