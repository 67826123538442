<script lang="ts" setup>
import { vIntersectionObserver } from "@vueuse/components";
import VCatalogPreview from "~/containers/VCatalogPreview.vue";
import VAuthBanner from "~/containers/VAuthBanner.vue";
import VSpecialOffers from "~/containers/VSpecialOffers/VSpecialOffers.vue";
import VAppInfo from "~/containers/VAppInfo.vue";
import VPrivateLabels from "~/containers/VPrivateLabels.vue";
import VUsefulYaSlot from "~/containers/VUseful/VUsefulYaSlot.vue";
import VPopularActionsSlider from "~/containers/VPopularActionsSlider.vue";
import VNovelties from "~/containers/VNovelties.vue";
import VUseful from "~/containers/VUseful/VUseful.vue";
import { useContentStore } from "~/store/content";

definePageMeta({
  layout: "default",
});

const contentStore = useContentStore();
await contentStore.requestNewArrival();

// Analytics
const { send } = useAnalytics();
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("MainPage:View");
  }
};
</script>

<template>
  <main v-intersection-observer="onVisibility" class="main-page">
    <SectionsMainSlider />
    <VPopularActionsSlider />
    <VSpecialOffers />
    <SectionsTicker />
    <VCatalogPreview />
    <VAuthBanner />
    <VNovelties />
    <VAppInfo />
    <VPrivateLabels />
    <SectionsWeekProducts />
    <VUsefulYaSlot />
    <VUseful />
    <!-- <VBrands /> -->
    <!-- <VPresentBoxes /> -->
  </main>
</template>

<style lang="postcss">
.main-page {
  &__loader {
    height: calc(100vh - 56px);
  }
}
</style>
